.circular-progress {
    /* Set relative positioning for absolute children */
    position: relative;
}

.progress-ring {
    /* Create a circular shape */
    border: 5px solid red;
    /* Set initial border color to transparent */
    border-top-color: #3B82F6;
    border-right-color: #3B82F6;
    border-top-left-radius: 50%;
    border-radius: 50%;
    /* Set the size of the progress bar */
    width: 100%;
    height: 100%;
    /* Ensure the progress bar starts from the top */
    transform: rotate(40deg);
}

.progress-value {
    /* Center the progress value text */
    width: 100%;
    height: 100%;
    /* Overlay the progress value text on top of the progress bar */
    position: absolute;
    top: 0;
    left: 0;
}