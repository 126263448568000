.dateRange .react-datepicker {
    border-radius: 0px !important;
    border: 1px solid var(--Gray-100, #ECEFF2) !important;
    background: var(--Base-White, #FFF) !important;
    min-height: 321px !important;
    /* Shadow/xl */
    box-shadow: none !important;
}

.dateRange .react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--selected {
    background-color: #677990 !important;
    color: #ffffff !important;
}

.dateRange .react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--selected:hover {
    background-color: #F2F4F7 !important;
    color: #475467 !important;
}

.dateRange .react-datepicker__day-names {
    margin-top: 40px !important;
}