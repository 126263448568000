.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    width: 100%;
}

.pagination li {
    margin: 0 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination li.active {
    font-weight: 700;
    background-color: #D5DBE2;
    border-radius: 20px;
    color: #1D2939;

}

.pagination li a {
    text-decoration: none;
    color: #667085;
    font-size: 14px;
    font-weight: 500;
}

.pagination li a:hover {
    text-decoration: none;
}

.previous {
    justify-content: flex-start !important;
    display: flex !important;
    /* width: 100% !important; */
    flex: 1 !important;
    order: -1;

}



.next {
    justify-content: flex-end !important;
    display: flex !important;
    /* width: 100% !important; */
    flex: 1 !important;
    order: 1;
}