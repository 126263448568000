.react-datepicker__triangle {
    display: none;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    height: 40px;
    min-width: 100%;
    padding: 10px 14px;
    border: solid 1px #D5DBE2;
    border-radius: 8px;
    background: #ffffff;
    outline: none !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-top: 4px;
    /* position: relative; */
}

/* h-40 mt-1 shadow-xs disabled:bg-gray_50 bg-white rounded-l-lg px-2.5 border border-r-0 border-gray_200 focus:outline-0 focus:ring-0 focus:border focus:border-primary_Blue_300 focus:shadow-inputFocus text-gray_500 placeholder:font-normal placeholder:text-gray_500 w-[100%] */

.react-datepicker__input-container input:focus,
.react-datepicker__input-container input:active {
    outline: none !important;
    border: solid 1px #83D3FF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E6EFFF;
}

.react-datepicker__navigation--next {
    margin-top: 12px;

    background: url(../../assets/icons/rightArrow.svg) no-repeat;

}

.react-datepicker__navigation--previous {
    margin-top: 12px;

    background: url(../../assets/icons/leftArrow.svg) no-repeat;

}

.react-datepicker__navigation-icon::before {
    display: none !important;

}

.react-datepicker__current-month {
    color: #475467;
    font-weight: 500;
    font-size: 16px;
    margin-top: 2px;
    font-family: "Euclid Circular A";


}

.react-datepicker__header {
    background-color: #ffffff;
    border-bottom: 0;
    padding-top: 20px;
}

.react-datepicker__day-names {
    margin-top: 70px;
}

.react-datepicker__day-name {
    color: #475467;
    text-align: center;
    font-family: "Euclid Circular A";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.react-datepicker__day {
    color: var(--Gray-500, #677990);
    text-align: center;
    font-family: "Euclid Circular A";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.react-datepicker__day--selected {
    /* height: 27px; */
    width: 21px;
    color: #ffffff;
    border-radius: 100%;
    background: var(--Primary-Blue-600, #003399);
}

.react-datepicker {
    border-radius: 8px;
    border: 1px solid var(--Gray-100, #ECEFF2);
    background: var(--Base-White, #FFF);

    /* Shadow/xl */
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.react-datepicker__month {
    padding-bottom: 10px;
}

.react-datepicker__year-dropdown {
    height: 200px;
    overflow-y: auto;
}